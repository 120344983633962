<template>
    <div class="personal-contaienr">
        <div class="user-container" v-if="isShow">
            <div class="top">
                <div class="left">
                    <div class="img">
                        <img src="../../assets/images/personal/img_my_avatar.png"/>
                    </div>
                </div>
                <div class="right">
                    <p @click="click">点击登录</p>
                    <p>欢迎来到智慧农机小程序</p>
                </div>
            </div>
            <!-- <van-button type="info" class="btn-login" @click="click">登录/注册</van-button> -->
        </div>
        <div v-else class="user-container">
            <div class="top">
                <div class="left">
                    <div class="img">
                        <img src="../../assets/images/personal/img_my_avatar.png"/>
                    </div>
                </div>
                <div class="right">
                     <p>{{userName}}</p>
                    <p>智农农业产业园</p>
                </div>
            </div>
            <div class="btn-login-out" @click="loginOut">退出登录</div>
        </div>
        <div class="bottom">
            <div class="bottom-item" v-if="isYuanGong">
                <van-row>
                    <van-col span="6">
                        <router-link :to="{name: 'workOrder', params: {active: 'waitAccept'}}">
                            <div class="item">
                                <span class="dot"></span>
                                <img src="../../assets/images/personal/icon_my_new.png"/>
                                <p>待接单</p>
                            </div>
                        </router-link>
                    </van-col>
                    <van-col span="6">
                        <router-link :to="{name: 'workOrder', params: {active: 'waitComplete'}}">
                            <div class="item">
                                <span class="dot"></span>
                                <img src="../../assets/images/personal/icon_my_unfinshed.png"/>
                                <p>待完成</p>
                            </div>
                        </router-link>
                    </van-col>
                    <van-col span="6">
                        <router-link :to="{name: 'workOrder', params: {active: 'waitCheck'}}">
                            <div class="item">
                                <span class="dot"></span>
                                <img src="../../assets/images/personal/icon_my_waiting.png"/>
                                <p>待审核</p>
                            </div>
                        </router-link>
                    </van-col>
                    <van-col span="6">
                        <router-link :to="{name: 'workOrder', params: {active: 'historyRecord'}}">
                            <div class="item">
                                <img src="../../assets/images/personal/icon_my_all.png"/>
                                <p>历史记录</p>
                            </div>
                        </router-link>
                    </van-col>
                </van-row>
            </div>
            <div class="bottom-item" v-else-if="isAdmin">
                 <van-row>
                    <van-col span="8">
                        <router-link :to="{path: '/notificationIssuance'}">
                            <div class="item">
                                <img src="../../assets/images/personal/icon_my_notice.png"/>
                                <p>通知</p>
                            </div>
                        </router-link>
                    </van-col>
                    <van-col span="8">
                        <router-link :to="{path: '/pressReleases'}">
                            <div class="item">
                                <img src="../../assets/images/personal/icon_my_news.png"/>
                                <p>新闻</p>
                            </div>
                        </router-link>
                    </van-col>
                    <van-col span="8">
                        <router-link :to="{path: '/agriculturalRecords'}">
                            <div class="item">
                                <img src="../../assets/images/personal/icon_my_all.png"/>
                                <p>农事</p>
                            </div>
                        </router-link>
                    </van-col>
                </van-row>
            </div>
            <div class="bottom-item" v-if="isTourist">
                 <van-row>
                    <van-col span="8">
                        <!-- <router-link :to="{path: '/login'}"> -->
                            <div class="item" @click="judgeLogin">
                                <img src="../../assets/images/personal/icon_my_notice.png"/>
                                <p>通知</p>
                            </div>
                        <!-- </router-link> -->
                    </van-col>
                    <van-col span="8">
                        <!-- <router-link :to="{path: '/login'}"> -->
                            <div class="item" @click="judgeLogin">
                                <img src="../../assets/images/personal/icon_my_news.png"/>
                                <p>新闻</p>
                            </div>
                        <!-- </router-link> -->
                    </van-col>
                    <van-col span="8">
                       <!--  <router-link :to="{path: '/login'}"> -->
                            <div class="item" @click="judgeLogin">
                                <img src="../../assets/images/personal/icon_my_all.png"/>
                                <p>农事</p>
                            </div>
                      <!--   </router-link> -->
                    </van-col>
                </van-row>
            </div>
            <div class="bottom-item" v-if="!isTourist">
                <router-link to="/addInfor" v-if="isPerson">
                    <p class="notice-list"><span>个人信息</span> <span></span></p>
                </router-link>
                <router-link to="/addressBook" v-if="isAdmin">
                    <p class="notice-list"><span>通讯录</span> <span></span></p>
                </router-link>
                <router-link to="/notificationIssuance" v-if="isPerson">
                    <p class="notice-list"><span>通知列表</span> <span></span></p>
                </router-link>
                <router-link to="/pressReleases" v-if="isPerson">
                    <p class="notice-list"><span>新闻列表</span> <span></span></p>
                </router-link>
            </div>
        </div>

    </div>
</template>

<script>
// 页面使用
import wx from 'weixin-js-sdk'
import { Dialog } from 'vant';
import {login, logout} from '@/api/user';
import {
    getToken,
    setToken,
    removeToken,
    getUserType,
    removeUserType,
    getUsername,
    removeUsername,
    removeOrgId
} from '@/utils/cookie'
export default {
    data() {
        return {
            userName: '',
            isTourist: false,//判断是否是游客
            isAdmin: true,//判断是否是管理员
            isPerson: true,//判断是否是员工
            isShow: true, //是否显示登录注册按钮
            isYuanGong: false,
            imgs: [
                {url: require('../../assets/images/home/u102.png')},
                {url: require('../../assets/images/home/u102.png')},
                {url: require('../../assets/images/home/u102.png')},
            ],
        }
    },
    created() {
        
    },
    mounted() {
        this.$nextTick(() => {
            this.isShow = getToken() ? false : true;
            this.isTourist = getUserType() == 0 ? true : false;
            this.isAdmin = getUserType() == 1 ? true : false;
            this.isPerson = getUserType() == 2 ? true : false;
            this.isYuanGong = getUserType() == 2 ? true : false;
            this.userName = getUsername();
        });
        
    },
    methods: {
        click() {
            this.$router.push({path: '/login'});
        },
        loginOut() {
            Dialog.confirm({
                title: '提示',
                message: '确定退出当前登录?',
            })
            .then(() => {
                if (this.isTourist) {
                    removeUsername();
                    removeUserType();
                    this.$router.push({path: '/console'});
                } else {
                    logout({token: getToken()}).then(res => {
                        if (res.code == 200) {
                            removeToken();
                            removeUserType();
                            removeUsername();
                            removeOrgId();
                            // setToken('');
                            this.$router.push({path: '/console'});
                        }
                    })
                }
            })
            .catch(() => {
                // on cancel
            });
            
        },
        judgeLogin() {
            if (this.isShow && !this.isTourist) {
               /*  this.$dialog.confirm({
                    title: '提示',
                    message: '当前未登录，请先登录',
                }).then(() => {
                    this.$router.push({path: '/login'});
                }).catch(() => {
                    this.$router.push({path: '/personal'});
                }); */
            }
        },
        /** 待对接提示 */
        dockTip() {
            this.$dialog.confirm({
                title: '提示',
                message: '待对接....',
            }).then(() => {
               
            }).catch(() => {
                
            });
        },
        jumpWx() {
            let path = `/pages/scan/scan`
            wx.miniProgram.navigateTo({
	          url: path,
	        })
        },
        judgeLogin() {
            this.$dialog.confirm({
                title: '提示',
                message: '当前功能需要登录才能查看',
                confirmButtonText: '去登录',
                cancelButtonText: '再看看'
            }).then(() => {
               this.$router.push({path: '/login'})
            }).catch(() => {
                
            });
        }
    },
}
</script>

<style lang="scss" scoped>
    .personal-contaienr {
        height: 100%;
        background-color: #F1F2F8;
        .btn-container {
            width: 100%;
            height: 180px;
            margin-top: 20px;
            text-align: center;
            img {
                width: 100%;
                height: 180px;
            }
            .btn-login {
                position: fixed;
                bottom: 70px;
                left: 12px;
                width: 343px;
                height: 44px;
                border-radius: 22px;
                border-width: 0;
                background-image: -moz-linear-gradient(to right, #30F2F8 , #2D81FF);
                background-image: -o-linear-gradient(to right, #30F2F8 , #2D81FF);
                background-image: -webkit-linear-gradient(to right, #30F2F8 , #2D81FF);
                background-image: linear-gradient(to right, #30F2F8 , #2D81FF);
            }
        }
        .user-container {
            .top {
                display: flex;
                align-items: center;
                width: 100%;
                height: 180px;
                background: #64AFFF;
                .left {
                    width: 100px;
                    text-align: center;
                    line-height: 130px;
                    img {
                        width: 54px;
                        height: 54px;
                        border-radius: 50%;
                        vertical-align: middle;
                    }
                }
                .right {
                    flex: 1;
                    padding: 20px 0;
                    p {
                        margin: 0;
                    }
                    p:nth-of-type(1) {
                        font-size: 18px;
                        color: #fff;
                    }
                    p:nth-of-type(2) {
                        margin-top: 8px;
                        font-size: 12px;
                        color: #fff;
                    }
                }
            }
            .btn-login-out {
                position: fixed;
                bottom: 77px;
                left: 14px;
                width: 347px;
                height: 43px;
                line-height: 43px;
                border-radius: 4px;
                font-size: 16px;
                text-align: center;
                color: #4499FF;
                border: 1px solid #4499FF;
            }
        }
        .bottom {
            position:absolute;
            top: 140px;
            left: 12px;
            width: 347px;
            margin: 0 auto;
            margin-top: 10px;
            padding: 12px 0;
            border-radius: 4px;
            .bottom-item {
                padding: 16px;
                // padding: 0 16px;
                box-shadow: 0px 0px 25px 0px rgba(209, 209, 209, 0.21);
                margin-bottom: 12px;
                text-align: center;
                border-radius: 4px;
                background-color: #fff;
                .item {
                    position: relative;
                    .dot {
                        position: absolute;
                        display: inline-block;
                        left: 46px;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: #FF6B6B;
                    }
                }
                
                img {
                    width: 32px;
                    height: 32px;
                }
                a{
                    + a p{
                        border-top: 1px solid #D8D8D8;
                    }
                    &:first-child p{
                        padding-top: 0;
                    }
                    &:last-child p{
                        padding-bottom: 0;
                    }
                }
                p {
                    font-size: 12px;
                    color: #636363;
                    text-align: center;
                }
                .notice-list {
                    padding: 16px 0;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    text-align: left;
                    font-size: 16px;
                    font-weight: 500;
                    color: #4F4F4F;
                    span:nth-of-type(2) {
                        display: inline-block;
                        width: 7px;
                        height: 7px;
                        border-top: 2px solid #C2C2C2;
                        border-right: 2px solid #C2C2C2;
                        color: #C2C2C2;
                        transform: rotate(45deg);
                    }
                }
            }
        }
    }
</style>